@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");
@tailwind base;

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary) var(--primary);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 15px;
}

*::-webkit-scrollbar-track {
  background: white;
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: black;
  border-radius: 20px;
  border: 3px solid white;
}

@tailwind components;
@tailwind utilities;
body {
  @apply leading-[normal] m-0;
}
*,
::before,
::after {
  border-width: 0;
}
button:hover{
  cursor: pointer;
}

/* The following CSS will make the scrollbar invisible until scrolling is started */

html {
  overflow-y: hidden;
}

body {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

::-webkit-scrollbar {
  display: none;
}

/* The following CSS will make the scrollbar visible again after a second of inactivity */

body {
  -webkit-overflow-scrolling: auto;
  overflow-y: auto;
}

.MeetingSideBar {
  font-size: 0.1rem;
}